import { parseISO, add, sub } from 'date-fns';
// Compile add and sub on object for access with variable
const relativeTime = { add, sub };

/**
 * Get Date for value.
 * Support String like ISO format or a Number.
 * If not valid value, return date at 2019-07-01T00:00:00Z.
 *
 * @param {String|Number} value Value of date on a String like ISO format or a Number
 *
 * @return {Date}
 */
export default function parseDate(value) {
  const regDate = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])(?![T]))/;

  // If default is now
  if (value === 'now') {
    return new Date();
  }

  // If hour not define set 00:00:00Z to prevent time difference
  if (typeof value === 'string' && value.match(regDate)) {
    return parseISO(`${RegExp.$1}T00:00:00Z`);
  }
  // if value like ISO format (yyyy-MM-ddTHH:mm:ssZ)
  if (typeof value === 'string' && !Number.isNaN(parseISO(value).getTime())) {
    return parseISO(value);
  }

  // If value is time Number create date at time
  if (value !== null && !Number.isNaN(Number(value))) {
    return new Date(Number(value));
  }

  /**
   * Example config:
  {
    type: add // type [add, sub]
    value: { // value @see https://date-fns.org/v2.9.0/docs/add
      years: 2
    }
  }
   */
  // If is object with type add or sub
  if (value !== null && typeof value === 'object' && Object.keys(relativeTime).includes(value.type)) {
    return relativeTime[value.type](new Date(), value);
  }

  // Fallback date
  return parseISO('2019-07-01T00:00:00Z');
}
