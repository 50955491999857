<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :prepend-icon="prependIcon"
        :value="formattedDatetime"
        v-on="on"
        readonly
      >
        <template v-slot:append>
          <slot name="append">
          </slot>
        </template>
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text style="padding: 0;">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>event</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>access_time</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePicker"
              @input="showTimePicker"
              full-width
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="time"
              v-bind="timePickerProps"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn @click.native="clearHandler">{{ clearText }}</v-btn>
          <v-btn color="primary" @click="okHandler">{{ okText }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parse } from 'date-fns';
import parseDate from '@/misc/parseDate';

const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00:00';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'CLEAR';
const DEFAULT_OK_TEXT = 'OK';

export default {
  name: 'v-datetime-picker',
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    datetime: {
      type: [Date, String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: 'HH:mm',
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
  },
  data: () => ({
    display: false,
    activeTab: 0,
    datePicker: null,
    date: DEFAULT_DATE,
    time: DEFAULT_TIME,
  }),
  created() {
    // Parse datePickerProps
    if (typeof this.datePickerProps === 'object') {
      this.datePicker = { ...this.datePickerProps };
      // If minDate is defined parse correct date (format: yyyy-MM-dd)
      if (this.datePickerProps.minDate) {
        this.datePicker.min = parseDate(this.datePickerProps.minDate).toISOString();
      }
      // If maxDate is defined parse correct date (format: yyyy-MM-dd)
      if (this.datePickerProps.maxDate) {
        this.datePicker.max = parseDate(this.datePickerProps.maxDate).toISOString();
      }
    }

    // If datetime is defined
    if (this.datetime) {
      let initDateTime;
      // If datetime is already a date
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime;
      } else { // Otherwise parse the date
        initDateTime = parseDate(this.datetime);
        this.$emit('input', initDateTime);
      }

      this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
      this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
    }
  },
  computed: {
    dateTimeFormat() {
      return `${this.dateFormat} ${this.timeFormat}`;
    },
    defaultDateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedDatetime() {
      return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : '';
    },
    selectedDatetime() {
      // If is default date and time
      if (this.date !== DEFAULT_DATE || this.time !== DEFAULT_TIME) {
        let datetimeString = `${this.date} ${this.time}`;
        if (this.time.length === 5) {
          datetimeString += ':00';
        }
        return parse(datetimeString, this.defaultDateTimeFormat, new Date());
      }
      return null;
    },
    dateSelected() {
      return !this.date;
    },
  },
  methods: {
    okHandler() {
      this.resetPicker();
      this.$emit('input', this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit('input', null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
};
</script>

<style lang="scss">
.v-time-picker-custom {
  width: 100%;
}
</style>
