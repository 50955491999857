<template>
  <v-datetime-picker
    :label="$t(label)"
    :prepend-icon="icon"
    v-model="value"
    :date-picker-props="datePicker"
    :time-picker-props="timePicker"
    :date-format="$t('fields.dateFormat')"
    :disabled="fieldReadonly"
  >
    <v-tooltip slot="append" bottom v-if="$t(description)">
      <template v-slot:activator="{ on }">
        <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
      </template>
      <span>{{ $t(description) }}</span>
    </v-tooltip>
  </v-datetime-picker>
</template>

<script>
import baseTextInput from '@/mixins/baseTextInput';
import VDatetimePicker from '@/components/DatetimePicker.vue';

export default {
  mixins: [
    baseTextInput,
  ],
  components: {
    VDatetimePicker,
  },
  props: {
    label: {
      type: String,
      default: 'fields.dateTime',
    },
    description: {
      type: String,
      default: 'fields.dateTimeTooltip',
    },
  },
  computed: {
    timePicker() {
      return {
        color: this.color,
        format: '24hr',
      };
    },
    datePicker() {
      const datePicker = {
        color: this.color,
        firstDayOfWeek: 1,
      };

      if (typeof this.options.minDate !== 'undefined') {
        datePicker.minDate = this.options.minDate;
      }

      if (typeof this.options.maxDate !== 'undefined') {
        datePicker.maxDate = this.options.maxDate;
      }

      return datePicker;
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.$emit('change', value.getTime());
      } else {
        this.$emit('change', null);
      }
    },
  },
};
</script>
